.registration-table {
  tr {
    td {
      padding-left: 5px;
      padding-right: 5px;
      vertical-align: top; /* validation feedback shows up below */
    }

    td:nth-child(2) {
      min-width: 160px;
    }

    td:nth-child(3) {
      min-width: 120px;
    }

    td:nth-child(4) {
      min-width: 80px;
      width: 80px;
    }

    td:nth-child(5) {
      min-width: 140px;
    }

    td:nth-child(6) {
      min-width: 140px;
    }

    td:nth-child(7) {
      min-width: 260px;
    }

    td:nth-child(8) {
      width: 1%;
      white-space: nowrap;
      text-align: center;
    }
  }
}