.icon-button {
  @extend .btn, .btn-light, .border;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: $secondary;
}
