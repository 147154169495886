.header {
  flex-wrap: nowrap;

  .navbar {
    padding: .5rem 0;
  }

  &__toggler {
    flex-grow: 1;
    text-align: end;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: ($spacer);
  }

  &__navbar-brand {
    @extend .navbar-brand;
    margin-right: 5px;
  }

  &__name {
    font-size: $font-size-sm;
    display: none;
  }

  @media (min-width: 360px) {
    &__name {
      display: block;
    }
  }

  @include media-breakpoint-up(sm) {
    &__navbar-brand {
      margin-bottom: 3px;
    }

    &__name {
      font-size: $font-size-lg;
    }
  }

  @include media-breakpoint-up(lg) {
    &__admin-icons {
      display: flex;
    }

    &__actions {
      margin-top: 0;
      flex-direction: row;
      align-items: center;
    }
  }

  @include media-breakpoint-up(xl) {
    &__nav {
      &--admin {
        font-size: 13px;
      }
    }
  }
}
