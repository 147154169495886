.table-page {
  padding-top: 1rem;
  font-size: $font-size-sm;

  &__title {
    font-size: $font-size-lg;
  }

  &__with-popup-wrap {
    padding-bottom: 170px;
    margin-bottom: -170px;
  }

  &__table {
    font-size: $font-size-sm;
    
    td {
      padding: .5rem;
      vertical-align: inherit;
    }

//     thead {
//       text-transform: capitalize;
//     }

    .form-control {
      font-size: $font-size-sm;
    }
  }

  &__actions-col {
    width: 1%;
    white-space: nowrap;
    text-align: center;
  }

  &__bottom-row {
    font-size: $font-size-sm;
  }
}


@include media-breakpoint-up(sm) {
  .table-page {
    font-size: $font-size-base;

    &__conditions {
      font-size: $font-size-base;
    }
  }
}

@include media-breakpoint-up(lg) {
  .table-page {
    &__title {
      font-size: 2rem;
    }

    &__table {
      font-size: $font-size-base;

      td {
        padding: .75rem;
      }

      .form-control {
        font-size: $font-size-base;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}