// bootstrap
@import './bootstrap-overrides/bootstrap-overrides.scss';
@import './bootstrap/bootstrap.scss';

// utils
@import './utils/animation';

// components
@import './components/header';
@import './components/footer';
@import './components/popup';
@import './components/buttons';
@import './components/tabs';
@import './components/alert';
@import './components/registration-table';
@import './components/order-table';
@import './components/table-sort';

// pages
@import './pages/table-page';
@import './pages/settings';
@import './pages/deliveries';

html {
  font-size: 15px;
}

p, label {
  margin-bottom: 0;
}

p {
  color: $secondary;
}

.form-padding {
    padding-top: 10%;
}

#addCropToPoint > ul,
#addCropToUser > ul {
    background-color: white;
    display: none;
    width: 100%;
}

select {
  outline: none;
  appearance: unset;
  -moz-appearance: unset;
  -webkit-appearance: unset;
  -o-appearance: unset;
  -ms-appearance: unset;
}

.form-control {
  padding-left: 6px;
  padding-right: 6px;
  height: calc(2.25rem + 2px);
}

select.form-control {
  padding-right: 12px;
  background: $custom-select-bg $custom-select-indicator no-repeat right 4px center;
  background-size: $custom-select-bg-size;
}
.popup-parent .popup {
  width: 500px;
  background-color: #eefcff;
}
.popup-description {
  overflow: hidden;
}
.description-editor{
  min-height: 150px;
}

table.sortable th:first-child,
table.sortable td:first-child {
  width: 1ch;
}
table.sortable td:first-child i {
  cursor:all-scroll;
}

.alert-container {
  // top and right are set by default, but long alert boxes that go all the way to the left would not have any margin
  margin-left: 15px
}

#reportTable th:not(.cursor-default) {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  padding-right: 1rem;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 30%;
    width: 15px;
    height: 20px;
    background-image: url('../images/sort_both.png');
  }

  &.asc:after {
    background-image: url('../images/sort_asc.png');
  }

  &.desc:after {
    background-image: url('../images/sort_desc.png');
  }
}
