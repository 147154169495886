.popup-parent {
  position: relative;

  & .popup {
    display: none;
    width: 400px;
    background-color: #ffffff;
    color: #000000;
    border-radius: 6px;
    box-shadow: 0 2px 5px #ccc;
    position: absolute;
    z-index: 1;
    left: 0; 
    top: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
    }
  }

  & .popup.show {
    display: block;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
  }
}
