$container-max-widths: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
) !default;

body {
  position: relative;
  min-height: 100vh;
}
