th.admin-tb {
  background-repeat: no-repeat;
  background-position: center right;

  &.sort-asc {
    background-image: url("../images/sort_asc.png");
  }

  &.sort-desc {
    background-image: url("../images/sort_desc.png");
  }

  &.sort-both {
    background-image: url("../images/sort_both.png");
  }
}
