$footer-sm-height: 160px;
$footer-height: 130px;

body::after {
  content: '';
  display: block;
  height: $footer-sm-height;
}

.footer {
  position: absolute;
  bottom: 0;
  height: $footer-sm-height;
  font-size: $font-size-sm;
  
  &__logo-image {
    width: 160px;
  }
  
  &__description {
    text-align: right;
  }
  
  &__contact {
    text-align: center;
    padding-top: $spacer;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@include media-breakpoint-up(sm) {
  body::after {
    height: $footer-height;
  }

  .footer {
    height: $footer-height;

    &__description {
      text-align: justify;
    }

    &__contact {
      flex-direction: column;
      text-align: right;
      padding-top: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .footer {
    font-size: $font-size-base;
  }
}
